<template>
  <div
    class="account-sidebar-element-wrapper"
    @click="navigateToChosenOption(title)"
  >
    <div
      class="account-sidebar-element"
      :class="{'account-sidebar-element-active-state': currentOption === 'Account'+title}"
    >
      <div class="account-sidebar-element-title">{{title}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'title',
    'appOffsetWidth',
  ],
  computed: {
    currentOption() {
      return this.$route.name
    },
  },
  methods: {
    navigateToChosenOption(option) {
      if (this.appOffsetWidth <= 1024) {
        this.$emit('closeSidebar')
      }
      
      this.$router.push({ name: 'Account' + option })
    }
  }
}
</script>

<style lang="scss">
  @import '../../../assets/styleVars.scss';
  .account-sidebar-element {
    margin-top: 20px;
    width: 100%;
    height: 19px;
    position: relative;
    font-size: $fontSizeStandart;
    color: $fontColorSidebarGray;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    .account-sidebar-element-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .account-sidebar-element-active-state {
      color: $fontColorBlack;
  }
</style>