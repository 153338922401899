<template>
  <div class="main-container">
    <div class="header">
      <div class="header-row">
        <div class="go-back-arrow" @click="handlePrevPages()"></div>
        <div class="account-sidebar-btn" @click="toogleSidebar"></div>
        <div class="account-logo"></div>
      </div>
    </div>
    <div class="content">
      <div
        class="sidebar-menu"
        :class="{'sidebar-menu-opened': isSidebarOpen}"
      >
        <div class="account-sidebar-close-btn" @click="toogleSidebar" v-if="isSidebarOpen"></div>
        <AccountSidebar
          :isOpened="isSidebarOpen"
          :options="sidebarOptions"
          :isCloseBtnSwown="isSidebarCloseBtnShown"
          :appOffsetWidth="appDiv.offsetWidth"
          @closeSidebar='toogleSidebar'
        />
      </div>
      <div class="main-contents">
        <div class="main-form">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import AccountSidebar from './accountSidebar/AccountSidebar.vue'

import { UserRole } from '../../utils/constants'

export default {
  components: {
    AccountSidebar,
  },
  data(){
    return {
      appDiv: null,
      isSidebarOpen: true,
      isSidebarCloseBtnShown: false,
    }
  },
  created() {
    this.appDiv = document.getElementById('app')
  },
  computed: {
    ...mapState([
      'currentUser',
    ]),
    sidebarOptions() {
      if (this.currentUser.role === UserRole.B2BUserActive
          || this.currentUser.role === UserRole.B2BOwner) return ['Settings']
      
      return ['Billings', 'Settings']
    },
  },
  watch: {
    appDiv(newValue, oldValue) {
      if (newValue.offsetWidth <= 1024) {
        this.isSidebarOpen = false
        this.isSidebarCloseBtnShown = true;
        return
      }

      if (newValue.offsetWidth > 1024) {
        this.isSidebarOpen = true
        this.isSidebarCloseBtnShown = false;
        return
      }
    }
  },
  methods: {
    currentRoute(){
        return this.$route.name
    },
    handlePrevPages() {
      this.$router.push({ name: 'Clients' })
    },
    toogleSidebar () {
      this.isSidebarOpen = !this.isSidebarOpen
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styleVars.scss';

.main-container {
  width: 100%;
  height: 100%;
  background-color:#E5E5E5;
  overflow: auto;
}

.header {
  width: 100%;
  height: 64px;
  background-color: #13141F;
  display: flex;
  position: relative;
  align-items: center;
  align-content: space-around;

  .header-row {
    margin-left: 55px;
    width: 50%;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;

    .account-sidebar-btn {
      width: 0;
      height: 0;
      position: absolute;
      background-size: 24px;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('../../assets/icons/hamburger-icon.svg')
    }

    .go-back-arrow {
      background-image: url('../../assets/icons/go-back-arrow.svg');
      width: 24px;
      height: 24px;
      cursor: pointer;
      position: relative;
    }

    .account-logo {
      width: 100px;
      height: 55px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100px 55px;
      background-image: url('../../assets/images/simple_sales_logo_white.png');
    }
  }
}

.content {
  width: 1024px;
  height: calc(100% - 84px);
  display: flex;
  margin: auto;
  margin-top: 20px !important;
  flex-direction: row;
  margin-top:40px;

  .sidebar-menu-opened {
    width: 240px !important;
  }

  .sidebar-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .account-sidebar-item {
      color: $fontColorGray;

      li {
        padding: 20px;
        list-style-type: none;
        cursor:pointer;
      }

      .sidebar-item-active {
        color: $fontColorBlack;
      }
    }

    .account-sidebar-close-btn {
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('../../assets/icons/close-icon.svg');
    }
  }

  .main-contents {
    width: 720px;
    height: calc(100% - 20px);

    .main-form {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #FFFFFF;
      border-radius: 4px;
      background-size: 24px 24px;
      box-sizing: border-box;
      padding-bottom: 20px;
    }
  }
}

.billing-main-content {
  width: 720px;
  height: 980px;
}

@media (max-width: 1024px) {
  .main-container {
    overflow: auto;
  }

  .header-row {
    .account-sidebar-btn {
      position: relative;
      width: 24px;
      height: 24px;
      margin-left: 5px;
    }

    .go-back-arrow {
      margin-left: -35px;
    }
  }

  .sidebar-menu-opened {
    width: 280px !important;
    height:100% !important;
    background-color: #E5E5E5;
    z-index: 999999;
    position: fixed;
    padding-left: 24px;
  }

  .header-row:deep(.account-sidebar-btn) {
    position: relative !important;
    width: 24px !important;
    height: 24px !important;
    margin-left: 5px;
  }

  .account-sidebar-close-btn {
    width: 24px !important;
    height: 24px !important;
    position: absolute;
    top: -20px;
    left: 8px;
  }
}

@media (max-width: 420px) {
  .main-container {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .main-form {
    height: 1024px !important;
    overflow: scroll;
    display: flex;
    flex-direction: column;
  }

  .header-row {
    .account-sidebar-btn {
      position: relative;
      width: 24px;
      height: 24px;
      margin-left: 5px;
    }

    .go-back-arrow {
      margin-left: -35px;
    }
  }

  .sidebar-menu-opened {
    width: 280px !important;
    height:100% !important;
    background-color: #E5E5E5;
    z-index: 999999;
    position: fixed;
    padding-left: 24px;
  }

  .header-row:deep(.account-sidebar-btn) {
    position: relative !important;
    width: 24px !important;
    height: 24px !important;
    margin-left: 5px;
  }

  .account-sidebar-close-btn {
    width: 24px !important;
    height: 24px !important;
    position: relative;
    margin-left:-24px;
  }
}
</style>
