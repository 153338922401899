<template>
  <div
    class="account-sidebar"
    :class="{
      'account-sidebar-opened':isOpened
    }"
    >
    <div class="account-sidebar-header">
      <div
        v-if="isCloseBtnShown"
        class="account-close-sidebar-btn"
        @click="$emit('closeSidebar')"
        >
      </div>
      <div class="account-sidebar-title">Account</div>
    </div>
    <AccountSidebarElement
      v-for="(option, index) in options"
      :key="index"
      :title="option"
      :appOffsetWidth="appOffsetWidth"
      @closeSidebar="$emit('closeSidebar')"
    />
    <FeedbackBtn />
  </div>
</template>

<script>
import AccountSidebarElement from './AccountSidebarElement.vue'
import FeedbackBtn from '../../common/FeedbackBtn.vue'

export default {
  props: [
    'options',
    'isOpened',
    'isCloseBtnShown',
    'appOffsetWidth',
  ],
  components: {
    AccountSidebarElement,
    FeedbackBtn,
  },
}
</script>

<style lang="scss">
  @import '../../../assets/styleVars.scss';
  .account-sidebar {
    width: 0;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 200;
    transition: all 0.2s;
    box-sizing: border-box;
    padding-left: 8px;

    .account-sidebar-title {
      font-size: $fontSizeTitle
    }

    .account-sidebar-header {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;

      .account-close-sidebar-btn {
        position: relative;
        left: 16px;
        top:16px;
        width: 24px;
        height: 24px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 24px 24px;
        background-image: url('../../../assets/icons/hamburger-icon.svg');
        cursor: pointer;
      }
    }
  }

  .account-sidebar-opened {
    width: 240px;
  }

</style>

<style lang="scss" scoped>
  @import '../../../assets/styleVars.scss';

  .feedback-btn {
    color: $fontColorBlack;
    padding-left: 0;
  }
</style>